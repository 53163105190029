.contect_main {
    position: relative;
    padding: 100px 0;
    background: #f7f6f7;
}

.contact_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
    margin-bottom: 50px;
}

.contact_data_box .labels {
    font-size: 16px;
    font-weight: bold;
    color: rgb(26, 26, 26);
    margin-bottom: 5px;
}

.contact_data_box .form-control {
    font-size: 16px;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    margin-bottom: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.contact_data_box textarea {
    resize: none;
    height: 100px !important;
}

@media (max-width: 767px) {
    .contect_main {
        padding: 50px 0
    }

    .contact_content {
        gap: 8px;
    }

    .contact_data_box .labels {
        margin-top: 8px
    }

}