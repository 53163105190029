.banner_main_Black {
    position: relative;
    background: #000;
    padding: 80px 0;
    overflow: hidden;
}

.banner_data_Black {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.banner_data_Black p {
    text-transform: uppercase;
}

.banner_Data_Box_Black {
    position: relative;
}

.BFirst_image_Black {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: -250px;
}

.Bannerdata_main_row_Black {
    position: relative;
    display: flex;
    flex-direction: row;
}

.banner_data_Black .paragraph_Black {
    text-transform: none;
}

@media (max-width: 576px) {
    .BFirst_image_Black {
        margin-left: -80px !important;
    }
}

@media (max-width: 768px) {
    .banner_main_Black {
        position: relative;
        padding: 10% 0;
    }

    .Bannerdata_main_row_Black {
        flex-direction: column;
    }

    .banner_data_Black .paragraph_Black {
        text-align: start;
        text-transform: none;
    }

    .BFirst_image_Black {
        max-width: 100%;
        margin-left: -200px;
        margin-right: auto;
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .BFirst_image_Black {
        margin-left: -200px;
    }
}

@media (min-width: 1200px) {
    .banner_data_Black {
        max-width: 90%;
    }
}

@media (min-width: 1200px) and (max-width:1299px) {
    .BFirst_image_Black {
        margin-left: -250px;
    }
}

@media (min-width: 1300px) and (max-width:1399px) {
    .BFirst_image_Black {
        margin-left: -460px;
    }
}

@media (min-width: 1400px) and (max-width:1599px) {
    .BFirst_image_Black {
        margin-left: -350px;
    }
}

@media (min-width: 1600px) {
    .BFirst_image_Black {
        margin-left: -270px;
    }
}