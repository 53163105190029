.header_main {
    padding: 25px 0;
    position: fixed;
    width: 100%;
    z-index: 1;
    transition: all .5s ease-out;
    -webkit-transition: all .5s ease-out;
}

.header_main.scrolled {
    background: #fff;
    padding: 0;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
    color: #000;
    transition: all .5s ease-out;
    -webkit-transition: all .5s ease-out;
}

.navbar-expand-md .navbar-collapse {
    justify-content: flex-end;
}

.navbar-brand {
    width: 100%;
    max-width: 200px;
}

.header_main.scrolled .nav-link {
    color: #1A1A1A;
}

.navbar-nav .nav-link {
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-left: 20px;
    text-transform: uppercase;
}

.navbar-nav .nav-link.active {
    color: #0377ae;
}

/* ________________ dorpdown button css _____________ */
.dropdown-menu {
    display: none;
}

.Dropdown-item {
    font-size: 12px;
    line-height: 10px;
    color: #1a1a1a;
    font-weight: 500;
}

/* _______________ dorpdown css over ______________ */
@media (min-width: 768px) {
    .nav-link:hover .dropdown-menu {
        display: block !important;
        width: 200px;
        padding: 15px;
        white-space: inherit;
        transition: all .5s ease-out;
        -webkit-transition: all .5s ease-out;
    }

    .drop_li {
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .header_main {
        position: relative;
    }

    .navbar-toggler {
        border: none;
        background-color: transparent;
        position: relative;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-toggler span {
        background-color: #000;
        width: 30px;
        height: 3px;
        display: block;
        margin-bottom: 6px;
        transition: 0.5s;
        position: relative;
        background-image: nopen
    }

    .navbar-toggler .navbar-toggler-icon:last-child {
        margin: 0;
    }

    .navbar-toggler.open span:first-child {
        top: 9px;
        transform: rotate(45deg);
    }

    .navbar-toggler.open span:nth-child(2) {
        opacity: 0;
    }

    .navbar-toggler.open span:last-child {
        top: -9px;
        transform: rotate(-45deg);
    }

    .navbar-nav .nav-link {
        color: #000;
        text-transform: uppercase;
    }

    /* dropdown css */
    .dropdown-toggle::after {
        display: block;
    }

    .main_drop {
        display: flex;
        justify-content: space-between;
    }

    .dropdown-menu {
        border: none;
        width: 100%;
    }

    .block {
        display: block;
        transition: all .5s ease-out;
    }

    .drop_li {
        margin-bottom: 10px;
        margin-left: 30px;
    }

    .Dropdown-item {
        font-size: 12px !important;
        line-height: 10px !important;
        color: #1a1a1a !important;
        font-weight: 500 !important;
    }

    .drop_li:last-child {
        margin-bottom: 0;
    }

    .GreatedIcon {
        font-size: 20px
    }

    .btn-div {
        height: fit-content;
        cursor: pointer;
        padding: 4px 12px 4px 12px;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
    }

    .rotate {
        rotate: 270deg;

    }
}

@media (max-width: 992px) {
    .header_main {
        padding: 0px 0;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 100%;
        padding: 0px 5%;
    }
}