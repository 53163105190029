.btn_main {
    background-color: #0377ae;
    border-radius: 5px;
    box-shadow: none;
    border: 0;
    font-size: 14px;
    color: #fff;
    line-height: 2em !important;
    cursor: pointer;
    display: block;
    outline: none;
    text-align: center;
    padding: 12px 20px;
    width: auto;
    white-space: nowrap;
    text-transform: uppercase;
}

.btn_main:hover {
    color: #fff;
    opacity: 1;
    box-shadow: inset 0 0 0 20rem rgba(0, 0, 0, 0.1), 0 2rem 2rem rgba(0, 0, 0, 0.1);
}

@media (max-width:575px) {
    .btn_main {
        padding: 10px 18px;
    }
}