.footer_main {
    padding: 4rem 0;
    background: #1a1a1a;
    text-align: center;
    color: #fff;
}

.footer_main p {
    text-align: center;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    opacity: 0.7;
}

.footer_main a,
Link {
    text-decoration: none;
    color: #fff;
}

@media (max-width: 767px) {
    .footer_main {
        padding: 40px 0;
    }
}