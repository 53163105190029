.service_main {
    position: relative;
    padding: 10% 0 10% 0;
}

.Service_Box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.service_main .content {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 40px;
}

.Service_img {
    text-align: center;
    width: 100%;
    height: 100%;
    max-width: 45px;
    max-height: 60px;
}

.Service_Box-item h5 {
    font-size: 14px;
    font-weight: 500;
}

.Service_Box-item p {
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;

}

.Service_Box-item h5,
.Service_Box-item p {
    text-align: center;
}

@media (max-width:767px) {
    .Service_Box-item {
        align-items: center;
        gap: 10px;
        margin-top: 30px;
    }

    .Service_img {
        width: 100%;
        height: 100%;
        max-width: 45px;
        max-height: 60px;
    }

    .Service_Box-item h5,
    p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
    }
}