.banner_main {
    position: relative;
    padding: 80px 0;
    overflow: hidden;
}

.banner_data {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.banner_Data_Box {
    position: relative;
}

.banner_data p,
.banner_data h2 {
    color: #1a1a1a;
}

.banner_data p {
    text-transform: uppercase;
}

.BFirst_image {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: -250px;
}

.Bannerdata_main_row {
    position: relative;
    display: flex;
    flex-direction: row;
}

.banner_data .paragraph {
    text-transform: none;
}

@media (min-width:320px) and (max-width: 576px) {
    .BFirst_image {
        margin-right: -80px !important;
    }
}

@media (max-width: 768px) {
    .banner_main {
        position: relative;
        background: #f7f6f7;
        padding: 10% 0;
    }

    .Bannerdata_main_row {
        flex-direction: column;
    }

    .banner_data .paragraph {
        text-align: start;
        text-transform: none;
    }

    .BFirst_image {
        display: block;
        max-width: 100%;
        margin-right: -180px;
        margin-left: auto;
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .BFirst_image {
        margin-right: -150px;
    }
}

@media (min-width: 1200px) {
    .BFirst_image img {
        height: 100%;
    }

    .banner_data {
        max-width: 90%;
    }
}

@media (min-width: 1200px) and (max-width:1299px) {
    .BFirst_image {
        margin-right: -200px;
    }
}

@media (min-width: 1400px) {
    .BFirst_image {
        margin-right: -250px;
    }
}