@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Hind", sans-serif;
}

li,
a {
  text-decoration: none;
  list-style-type: none;
  color: inherit;
}

/* scroll to top button css */
.scroll-to-top-button {
  display: inline-block;
  background: #08080860;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0.7;
  visibility: hidden;
  z-index: 1000;
  border: none;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.scroll-to-top-button::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('./assets/arrowup.svg');
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.scroll-to-top-button:hover {
  cursor: pointer;
  opacity: 1;
  background: #02020296;
  border-radius: 100%;
}

.scroll-to-top-button:active {
  background-color: #555;
}

.scroll-to-top-button.show {
  opacity: 1;
  visibility: visible;
}


.main_title {
  font-size: 48px;
  font-weight: 400;
  line-height: 62px;
  color: #FFF;
}

.main_title * {
  font-size: inherit;
  font-weight: inherit;
}

.regular_title {
  font-size: 50px;
  font-weight: 300;
  color: #1A1A1A;

}

.regular_title * {
  font-size: inherit;
  font-weight: inherit;
}

.regular_text {
  font-size: 16px;
  font-weight: 400;
  color: #1A1A1A;
}

.regular_text * {
  font-size: inherit;
  font-weight: inherit;
}

.widget_title {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.widget_title * {
  font-size: inherit;
  font-weight: inherit;
}

@media (max-width:767px) {
  .regular_title {
    font-size: 40px;
    font-weight: 300;
  }
}