.hero_main {
    position: relative;
    width: 100%;
    background: url('../../../assets/heroBg.jpg');
    background-size: cover;
}

.hero_content_wrap {
    color: #fff;
    padding-top: 15%;
    max-width: 1300px;
    margin: auto;
    padding-top: 220px;
    padding-bottom: 600px;
}

.hero_content_wrap .hero_subhead {
    font-size: 24px;
    font-weight: 400;
}

@media (max-width: 767px) {
    .hero_content_wrap {
        padding-top: 100px;
        padding-bottom: 220px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hero_content_wrap {
        padding-top: 130px;
        padding-bottom: 220px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hero_content_wrap {
        padding-top: 170px;
        padding-bottom: 229px;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .hero_content_wrap {
        padding-top: 190px;
        padding-bottom: 340px;
    }
}

@media (min-width: 1300px) and (max-width: 1399px) {
    .hero_content_wrap {
        padding-top: 200px;
        padding-bottom: 376px;
    }
}